import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Compliant } from '../../../asset/edit-1.svg';
import { ReactComponent as Dashboard } from '../../../asset/home.svg';
import { ReactComponent as Card } from '../../../asset/layers-1.svg';
import { ReactComponent as Logo } from '../../../asset/logo.svg';
import { ReactComponent as Insight } from '../../../asset/map-1.svg';
import { ReactComponent as More } from '../../../asset/more-1.svg';
import { ReactComponent as Transfer } from '../../../asset/shuffle.svg';
import { ReactComponent as Phone } from '../../../asset/smartphone.svg';
import { ReactComponent as USER } from '../../../asset/user.svg';
import TranferPage from '../../../pages/transfer/eventTransfer';
import Context from "../../context/login";
import TranferContext from '../../context/tranferContext';
import Classes from './nav.module.css';


/** @description NAVSECTION consisting of main nav and hero page */

const Nav = () => {
  const ctx =  useContext(Context)
  const transferCtx = useContext(TranferContext);
  const [dropDown, setDropDown] = useState(false);

  return (
    <>
      <div className={Classes.navCover}>
        <div className={Classes.nav}>
          <div className={Classes.brand}>{ctx.token ? <img src='http://chartered-expressgroup.com/chartered-express-logo.png' style={{width: "140px"}} /> : <span style={{color: "#fff"}}>Chartered Express</span> }</div>
          <div className={Classes.logout} style={{color: "#fff"}} onClick={()=>window.location.reload()}>LOGOUT</div>
        </div>
      </div>
      <div style={{display: ctx.token ? "block" : "none"}} className={Classes.heroCover}>
        <div className={Classes.nav}>
          <div className={Classes.ownerCover}>
            <h1 style={{textTransform: "capitalize"}}>Hi {ctx.details?.fname}</h1>
            <div>
              <Link
                to="/user_profile"
                style={{textDecoration: 'none', color: 'inherit'}}
              >
                <small>view profile </small> <span>{'>'}</span>
              </Link>
            </div>
            <div className={Classes.profileImage}>
              {ctx.details?.proPic && ctx.details?.proPic.length  > 2 ? <img src={ctx.details?.proPic} alt="profile" /> : <USER />}
            </div>
          </div>
          <div className={Classes.linkCover}>
            <Link style={{textDecoration: 'none'}} to="/dashboard">
              <EachLink svg={<Dashboard />} link_name="DASHBOARD" />
            </Link>
            <a
              onClick={() => transferCtx.setShowTransfer()}
              style={{textDecoration: 'none'}}
              href="#"
            >
              <EachLink svg={<Transfer />} link_name="TRANSFER" />
            </a>
{/* 
            <Link style={{textDecoration: 'none'}} to="/user/bills">
              <EachLink svg={<Bills />} link_name="BILLS AND PAYMENT" />
            </Link> */}
            <Link style={{textDecoration: 'none'}} to="/complaint">
              <EachLink svg={<Compliant />} link_name="COMPLAINT" />
            </Link>
            <Link style={{textDecoration: 'none'}} to="/my-bank-card">
              <EachLink svg={<Card />} link_name="MY BANK CARD" />
            </Link>
            <Link style={{textDecoration: 'none'}} to="/user_profile">
              <EachLink svg={<Phone />} link_name="PROFILE" />
            </Link>
            <Link style={{textDecoration: 'none'}} to="/insight">
              <EachLink svg={<Insight />} link_name="INSIGHT" />
            </Link>
            {/* <a onClick={() => transferCtx.setShowTransfer()} style={{ textDecoration: "none" }} href="#"> */}
            <a style={{textDecoration: 'none'}} href="#">
              <EachLink
                onClick={() => setDropDown(!dropDown)}
                svg={<More />}
                link_name="DO MORE"
              />
            </a>
          </div>
          <div
            style={{display: dropDown ? 'block' : 'none'}}
            className={Classes.dropDownCover}
          >
            <div className={Classes.dropDown}>
              <ul>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Dashboard />
                    Dashboard
                  </Link>
                </li>
                <li onClick={() => transferCtx.setShowTransfer()}>
                  <Link
                    to="/"
                    onClick={() => setDropDown(!dropDown)}
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Transfer />
                    Tranfer
                  </Link>
                </li>
                {/* <li >
                  <Link
                    to="/user/bills"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Bills />
                    Bill and payment
                  </Link>
                </li> */}
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/complaint"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                  <Compliant />
                    Compliant
                  </Link>
                </li>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/my-bank-card"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                  <Card />
                  My bank card
                  </Link>
                </li>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/user_profile"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                  <Phone />
                  Profile
                  </Link>
                </li>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/insight"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Insight />
                    Insight
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TranferPage />
    </>
  );
};

export default Nav;

export const EachLink = ({onClick, link_name, svg}) => (
  <div onClick={onClick} className={Classes.eachLinkCover}>
    <hr style={{transform: 'rotate(180deg)'}} />
    {svg}
    <small>{link_name}</small>
  </div>
);
