import React, { useState, useContext, useRef, useEffect } from "react";
import { Modal } from "../../componet/reuseable/reuseable";
import { country_arr, s_a } from "../shortcut/countries";
import Classes from "./transfer.module.css";
import Context from "../../componet/context/login";
import TraferContext from "../../componet/context/tranferContext";

const IntraBanking = ({ showModal, closeModal, modalDesc }) => {
  const transCTX = useContext(TraferContext);
  const [msg, setMsg] = useState("");
  const [msgToken, setMsgToken] = useState("");
  const ctx = useContext(Context);
  const [isParsed, setIsParsed] = useState(false);
  const tokenRef = useRef();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const emptyOBJ = {};

  const onSubmitFormHandler = (e) => {
    e.preventDefault();

    let spliting = tokenRef.current.value;

    if (spliting === ctx.details.random_token) {
      setIsParsed(true);
    } else {
      setTimeout(() => {
        setMsg("");
      }, 3000);
      setMsg("Invalid Token");
    }
  };
  // console.log("options", options)

  const submitFormTransfer = (e) => {
    e.preventDefault();
    // setTimeout(() => {
    //   setLoading(false)
    //   setMsg("Certificate Expired Please visit any nearby branch or contact your bank manager to activate your account.")
    // }, 2000);
    // setLoading(true)

    if (details.pin !== ctx.details.pin) {
      return setMsg("Invalid Pin Check Pin");
    }
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(`${ctx.url}user/action/transfer/fund`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `bearer ${ctx.token}`,
      }),
      body: JSON.stringify({
        ...details,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setDetails({ ...emptyOBJ });
        if (res.error) {
          return setMsg(res.msg);
        } else {
          setMsg("Transaction Successful");
          ctx.setToken(res.data.token);
          transCTX.setShowTransfer();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setMsg("Check your network connection");
      });
  };

  let [states, setStates] = useState([]);
  const getStateOfCountry = (e) => {
    country_arr.forEach((v, i) => {
      if (v === e.target.value) {
        setStates(s_a[i + 1].split("|"));
        console.log(v, i);
        console.log(s_a[i].split("|"));
        return;
      }
    });
    //
  };

  // const submitFormTransfer = (e) => {
  //   e.preventDefault();
  //   if (inputs.pin !== ctx.details.pin) {
  //      return setMsg('Invalid Pin Check Pin');
  //   }
  //   setLoading(true);
  //   fetch(`${ctx.url}user/action/transfer/fund`, {
  //     method: 'POST',
  //     headers: new Headers({
  //       'Content-Type': 'application/json',
  //       Authorization: `bearer ${ctx.token}`,
  //     }),
  //     body: JSON.stringify({
  //       description: inputs.naration,
  //       account_number: number,
  //       amount: inputs.amount,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setLoading(false);

  //       if (res.error) {
  //         return setMsg(res.msg);
  //       } else {
  //         setMsg("Transaction Successful");
  //         ctx.setToken(res.data.token)
  //         transCTX.setShowTransfer()
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //       setMsg('Check your network connection');
  //     });
  // };
  // console.log("details", details)

  const getToken = async () => {
    const getNewToken = await fetch(`${ctx.url}user/get/refresh/token`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `bearer ${ctx.token}`,
      }),
    });

    const data = await getNewToken.json();

    if (data.token) {
      ctx.setToken(data.token);
      setMsgToken(data.msg);
    } else {
      return null;
    }
  };

  useEffect(() => {
    setDetails({ ...emptyOBJ });
    setMsg("");
    if (!isParsed && showModal) {
      getToken();
    } else if (!showModal) {
      setMsgToken("");
    } else {
      return;
    }
  }, [showModal]);

  return (
    <Modal desc={modalDesc} showModal={showModal} closeModal={closeModal}>
      <div className={Classes.modalCover}>
        <div
          style={{ visibility: msg.length > 2 ? "visible" : "hidden" }}
          className={Classes.aboveMsg}
        >
          {msg}
        </div>
        {!isParsed && (
          <form
            onSubmit={onSubmitFormHandler}
            style={{ gridTemplateColumns: "1fr" }}
            className={Classes.form}
          >
            <h3 style={{ margin: "7px 0px", fontSize: "13px" }}>
              Enter your token{" "}
            </h3>
            <input
              placeholder="TOKEN"
              autoFocus
              ref={tokenRef}
              className={Classes.input}
              required
            />
            <h3 style={{ margin: "7px 0px", fontSize: "13px" }}>{msgToken}</h3>
            <button className={Classes.button}>CONTINUE</button>
          </form>
        )}

        {isParsed && (
          <form onSubmit={submitFormTransfer} className={Classes.form}>
            <input
              placeholder="Account Number"
              className={Classes.input}
              value={details?.account_number || ""}
              onChange={(e) =>
                setDetails({ ...details, account_number: e.target.value })
              }
            />

            <input
              placeholder="Full Name"
              className={Classes.input}
              value={details?.full_name || ""}
              onChange={(e) =>
                setDetails({ ...details, full_name: e.target.value })
              }
            />

            {modalDesc === "Transfer To Other Banks" ? (
              <>
                {" "}
                <select name="rstate" className="form-control">
                  <option>Receiver State</option>
                  <option value="Alabama">Alabama</option>
                  <option value="Alaska">Alaska</option>
                  <option value="Arizona">Arizona</option>
                  <option value="Arkansas">Arkansas</option>
                  <option value="California">California</option>
                  <option value="Colorado">Colorado</option>
                  <option value="Connecticut">Connecticut</option>
                  <option value="Delaware">Delaware</option>
                  <option value="Florida">Florida</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Idaho">Idaho</option>
                  <option value="Illinois">Illinois</option>
                  <option value="Indiana">Indiana</option>
                  <option value="Iowa">Iowa</option>
                  <option value="Kansas">Kansas</option>
                  <option value="Kentucky">Kentucky</option>
                  <option value="Louisiana">Louisiana</option>
                  <option value="Maine">Maine</option>
                  <option value="Maryland">Maryland</option>
                  <option value="Massachusetts">Massachusetts</option>
                  <option value="Michigan">Michigan</option>
                  <option value="Minnesota">Minnesota</option>
                  <option value="Mississippi">Mississippi</option>
                  <option value="Missouri">Missouri</option>
                  <option value="Montana">Montana</option>
                  <option value="Nebraska">Nebraska</option>
                  <option value="Nevada">Nevada</option>
                  <option value="New Hampshire">New Hampshire</option>
                  <option value="New Jersey">New Jersey</option>
                  <option value="New Mexico">New Mexico</option>
                  <option value="New York">New York</option>
                  <option value="North Carolina">North Carolina</option>
                  <option value="North Dakota">North Dakota</option>
                  <option value="Ohio">Ohio</option>
                  <option value="Oklahoma">Oklahoma</option>
                  <option value="Oregon">Oregon</option>
                  <option value="Pennsylvania">Pennsylvania</option>
                  <option value="Rhode Island">Rhode Island</option>
                  <option value="South Carolina">South Carolina</option>
                  <option value="South Dakota">South Dakota</option>
                  <option value="Tennessee">Tennessee</option>
                  <option value="Texas">Texas</option>
                  <option value="Utah">Utah</option>
                  <option value="Vermont">Vermont</option>
                  <option value="Virginia">Virginia</option>
                  <option value="Washington">Washington</option>
                  <option value="West Virginia">West Virginia</option>
                  <option value="Wisconsin">Wisconsin</option>
                  <option value="Wyoming">Wyoming</option>
                </select>
                <select name="r_bank" className="form-control">
                  <option>Select Beneficiary Bank</option>
                  <option value="Chase Bank">Chase Bank</option>
                  <option value="Bank of America">Bank of America</option>
                  <option value="Wells Fargo">Wells Fargo</option>
                  <option value="U.S. Bank">U.S. Bank</option>
                  <option value="BBVA Compass">BBVA Compass </option>
                  <option value="Bank of the west">Bank of the west</option>
                  <option value="Santander Consumer Bank">
                    Santander Consumer Bank
                  </option>
                  <option value="Citi Bank">Citi Bank</option>
                  <option value="Huntington Bank">Huntington Bank</option>
                  <option value="M&amp;T Bank">M&amp;T Bank</option>
                  <option value="Woodforest National Bank">
                    Woodforest National Bank
                  </option>
                  <option value="Citizens Bank">Citizens Bank</option>
                  <option value="Fifth Third Bank">Fifth Third Bank</option>
                  <option value="Key Bank">Key Bank</option>
                  <option value="TD Bank">TD Bank</option>
                  <option value="Sun Trust Bank">Sun Trust Bank</option>
                  <option value="Regions Bank">Regions Bank</option>
                  <option value="PNC Bank">PNC Bank</option>
                  <option value="BB&amp;T Bank">BB&amp;T Bank</option>
                  <option value="First National Bank">
                    First National Bank
                  </option>
                  <option value="BMO Harris Bank">BMO Harris Bank</option>
                  <option value="First Citizens Bank">
                    First Citizens Bank
                  </option>
                  <option value="Comerica Bank">Comerica Bank</option>
                  <option value="People's United Bank">
                    People's United Bank
                  </option>
                  <option value="Umpqua Bank">Umpqua Bank</option>
                  <option value="Bank of the Ozarks">Bank of the Ozarks</option>
                  <option value="HSBC">HSBC</option>
                  <option value="MUFG Union Bank">MUFG Union Bank</option>
                  <option value="Arvest Bank">Arvest Bank</option>
                  <option value="Chemical Bank">Chemical Bank</option>
                  <option value="TCF Bank">TCF Bank</option>
                  <option value="Synovus Bank">Synovus Bank</option>
                  <option value="Bancorp South Bank">Bancorp South Bank</option>
                  <option value="Washington Federal">Washington Federal</option>
                  <option value="Assiciated Bank">Assiciated Bank</option>
                  <option value="Iberiabank">Iberiabank</option>
                  <option value="Valley National Bank">
                    Valley National Bank
                  </option>
                  <option value="Whitney Bank">Whitney Bank</option>
                  <option value="Trust Mark National Bank">
                    Trust Mark National Bank
                  </option>
                  <option value="Great Western Bank">Great Western Bank</option>
                  <option value="Columbia State Bank">
                    Columbia State Bank
                  </option>
                  <option value="Centennial Bank">Centennial Bank</option>
                  <option value="Old National Bank">Old National Bank</option>
                  <option value="South State Bank">South State Bank</option>
                  <option value="First Tennessee Bank">
                    First Tennessee Bank
                  </option>
                  <option value="NBT Bank">NBT Bank</option>
                  <option value="Renasant Bank">Renasant Bank</option>
                  <option value="Banner Bank">Banner Bank</option>
                  <option value="Webster Bank">Webster Bank</option>
                  <option value="Simmons Bank">Simmons Bank</option>
                  <option value="United Bank">United Bank</option>
                  <option value="Frost Bank">Frost Bank</option>
                  <option value="WesBanco Bank">WesBanco Bank</option>
                  <option value="Commerce Bank">Commerce Bank</option>
                  <option value="Investors Bank">Investors Bank</option>
                  <option value="TrustCo Bank">TrustCo Bank</option>
                  <option value="First Commonwealth Bank">
                    First Commonwealth Bank
                  </option>
                  <option value="Sterling National Bank">
                    Sterling National Bank
                  </option>
                  <option value="Carter Bank And Trust">
                    Carter Bank And Trust
                  </option>
                  <option value="First Midwest Bank">First Midwest Bank</option>
                  <option value="First Bank">First Bank</option>
                  <option value="Park National Bank">
                    Park National Bank
                  </option>{" "}
                  <option value="Pinnacle Bank">Pinnacle Bank</option>
                  <option value="Glacier Bank">Glacier Bank</option>{" "}
                  <option value="Fulton Bank">Fulton Bank</option>
                  <option value="Rabobank">Rabobank</option>
                  <option value="Zions Bank">Zions Bank</option>
                  <option value="First Merchants Bank">
                    First Merchants Bank
                  </option>
                  <option value="East West Bank">East West Bank</option>
                  <option value="First Interstate Bank">
                    First Interstate Bank
                  </option>
                  <option value="Union Bank and Trust">
                    Union Bank and Trust
                  </option>
                  <option value="Great Southern Bank">
                    Great Southern Bank
                  </option>
                  <option value="Flagster Bank">Flagster Bank</option>
                </select>
              </>
            ) : (
              <>
                <input
                  required
                  value={details?.bname || ""}
                  onChange={(e) =>
                    setDetails({ ...details, bname: e.target.value })
                  }
                  placeholder="Receiver Bank Name"
                  type="text"
                  className={Classes.input}
                />
                <input
                  required
                  value={details?.code || ""}
                  onChange={(e) =>
                    setDetails({ ...details, code: e.target.value })
                  }
                  placeholder="Swift Code/Routing/IBAN"
                  type="text"
                  className={Classes.input}
                />
                <input
                  required
                  value={details.remail || ""}
                  onChange={(e) =>
                    setDetails({ ...details, remail: e.target.value })
                  }
                  placeholder="Receiver Email"
                  type="text"
                  className={Classes.input}
                />
                <select
                  id="country"
                  className="form-control"
                  required="required"
                  name="rcountry"
                  onChange={getStateOfCountry}
                >
                  <option value="-1">Select Country</option>

                  {country_arr.map((v, i) => (
                    <option key={i}>{v}</option>
                  ))}
                </select>{" "}
                <select
                  className="form-control"
                  name="rstate"
                  id="state"
                  required={true}
                >
                  <option>Select State</option>
                  {states.map((v, i) => (
                    <option key={i}>{v}</option>
                  ))}
                </select>{" "}
              </>
            )}

            <input
              required
              value={details?.amount || ""}
              onChange={(e) =>
                setDetails({ ...details, amount: e.target.value })
              }
              placeholder="Amount"
              type="number"
              className={Classes.input}
            />
            {modalDesc === "Transfer To Other Banks" && (
              <input
                required
                value={details?.routing_number || ""}
                onChange={(e) =>
                  setDetails({ ...details, routing_number: e.target.value })
                }
                placeholder="Routing Number Only"
                className={Classes.input}
              />
            )}
            <input
              required
              value={details?.description || ""}
              onChange={(e) =>
                setDetails({ ...details, description: e.target.value })
              }
              placeholder="Naration ..."
              className={Classes.input}
            />
            <input
              required
              value={details?.pin || ""}
              onChange={(e) => setDetails({ ...details, pin: e.target.value })}
              placeholder="PIN"
              className={Classes.input}
            />

            <div
              style={{ visibility: msg.length > 2 ? "visible" : "hidden" }}
              className={Classes.belowMsg}
            >
              {msg}
            </div>
            {loading ? (
              <button className={Classes.button}>...</button>
            ) : (
              <button className={Classes.button}>MAKE TRANSFER</button>
            )}
          </form>
        )}
      </div>
    </Modal>
  );
};

export default IntraBanking;
