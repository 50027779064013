import React, {useContext, useEffect, useState} from 'react';
import TranferContext from '../../componet/context/tranferContext';
import Context from '../../componet/context/login';
import {Modal} from '../../componet/reuseable/reuseable';
import Classes from './transfer.module.css';
import {ReactComponent as ArrowDown} from '../../asset/arrowDown.svg';
import {ReactComponent as Loading} from '../../asset/loader.svg';
import IntraBanking from './intraBanking';

const Transfer = ({
  inputs,
  OnChangeInputHandler,
  onChangeNumber,
  number,
  msg,
  submitFormTransfer,
  loadingName,
  loading,
}) => {
  const ctx = useContext(TranferContext);
  const mainCTX = useContext(Context);
  const [modalDesc,setModalDesc] = useState("")
  const [show, setShow] = useState([
    {name: 'select', active: false, selected: ''},
    {name: 'transfer', active: false, selected: 'Transfer To Same Bank'},
  ]);
  const [showExtraModal, setShowExtraModal] = useState(false);
  const options = ['Transfer To Same Bank', 'Transfer To Other Banks', "Wire Transfer"];

  const toggleSelectionHandler = (name, e) => {
    const spread = [...show];
    const findOne = spread.findIndex((v) => v.name === name);
    spread[findOne].active = !spread[findOne].active;
    setShow(spread);
  };

  const setValueHandler = (name, details, e) => {
    e.stopPropagation();
    const spread = [...show];
    const findOne = spread.findIndex((v) => v.name === name);
    spread[findOne].active = false;
    spread[findOne].selected = details;
    setModalDesc(details)
    setShow(spread);
  };

  const mapOptions = options.map((v, i) => {
    return (
      <div onClick={setValueHandler.bind(this, 'transfer', v)} key={i}>
        {v}
      </div>
    );
  });

  useEffect(() => {
    if (show[1].selected !== 'Transfer To Same Bank') {
      setShowExtraModal(true);
    } else {
      return;
    }
  }, [show]);

  const closeModalHandler = () => {
    setShowExtraModal(!showExtraModal);
    const spread = [...show];
    spread[1].selected = 'Transfer To Same Bank';
    setShow(spread);
  };

  return (
    <Modal
      showModal={ctx.showTransfer}
      closeModal={() => ctx.setShowTransfer()}
    >
      <div className={Classes.modalCover}>
        <h3>TRANSFER FUNDS</h3>
        <form onSubmit={submitFormTransfer} className={Classes.form}>
          <div
            onClick={toggleSelectionHandler.bind(this, 'select')}
            className={Classes.selectBox}
          >
            <strong>
              {show[0].selected.length < 3
                ? 'Select Account'
                : show[0].selected}
            </strong>
            <ArrowDown />
            <div
              style={{display: show[0].active ? 'block' : 'none'}}
              className={Classes.selectingBoxCover}
            >
              <div
                onClick={setValueHandler.bind(
                  this,
                  'select',
                  mainCTX.details?.account_number
                )}
              >
                {mainCTX.details?.account_number}
              </div>
            </div>
          </div>
          <div
            onClick={toggleSelectionHandler.bind(this, 'transfer')}
            className={Classes.selectBox}
          >
            <strong>
              {show[1].selected.length < 3 ? 'Transfer To Same Bank' : show[1].selected}
            </strong>
            <ArrowDown />
            <div
              style={{
                display: show[1].active ? 'block' : 'none',
                height: '100px',
              }}
              className={Classes.selectingBoxCover}
            >
              {mapOptions}
            </div>
          </div>

          <input
            placeholder="Account Number"
            className={Classes.input}
            onChange={onChangeNumber}
            value={number}
          />
          {loadingName ? (
            <div
              style={{
                width: '220px',
                display: 'flex',
                justifyContent: 'center',
                height: '52px',
              }}
            >
              <Loading style={{width: '42px', height: '42px'}} />{' '}
            </div>
          ) : (
            <div style={{ fontWeight: "bold",
    color: "red",
    fontSize: "13px", textTransform: 'capitalize'}}>{msg}</div>
          )}
          <input
            value={inputs.amount}
            required
            onChange={OnChangeInputHandler.bind(this, 'amount')}
            placeholder="Amount"
            type="number"
            className={Classes.input}
          />
          <input
            value={inputs.naration}
            required
            onChange={OnChangeInputHandler.bind(this, 'naration')}
            placeholder="Naration ..."
            className={Classes.input}
          />
          <input
            value={inputs.pin}
            required
            onChange={OnChangeInputHandler.bind(this, 'pin')}
            placeholder="PIN"
            className={Classes.input}
          />
          {show[1].selected !== 'Transfer To Same Bank' && (
            <input
              value={inputs.OTP}
              required
              onChange={OnChangeInputHandler.bind(this, 'OTP')}
              placeholder="OTP"
              className={Classes.input}
            />
          )}

          {loading ? (
            // <div
            //   style={{
            //     width: '180px',
            //     display: 'flex',
            //     justifyContent: 'center',
            //     height: '52px',
            //   }}
            // >
            //   <Loading style={{width: '52px', height: '52px'}} />{' '}
            // </div>
            <button className={Classes.button}>...</button>
          ) : (
            <button className={Classes.button}>MAKE TRANSFER</button>
          )}
        </form>
      </div>
      <IntraBanking modalDesc={modalDesc} showModal={showExtraModal} closeModal={closeModalHandler} />
    </Modal>
  );
};

export default Transfer;

// select account
// transfer to
// intra account, extra account
// naration
// amount
// account number to send to
// after the account, it fetches the account fullname
