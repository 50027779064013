import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function CardStatus() {
  let [show,setShow] = useState(false)
  useEffect(()=>{
    let TID = toast.loading('Loading approved cards.')
    setTimeout(()=>{
      toast.dismiss(TID)
      setShow(true)
    },3000)
  },[])
  return (
    <div className="container-fluid">
<br/>
<br/>
      {/* table */}
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header card-title">MY CARD LIST</div>
            <div className="table-responsive">
              <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">USER</th>
                    <th>CARD NO</th>
                    <th className="text-center">EXPIRY DATE</th>
                    <th className="text-center">SECURITY/CVV</th>
                    <th className="text-center">CARD PIN</th>
                    <th className="text-center">CARD TYPE</th>
                    <th className="text-center">STATUS</th>
                  </tr>
                </thead>

                <tbody style={{ overflow: "auto" }}>
                  {/* <tr>
                    <td className="text-center">USER</td>
                    <td>DATE</td>
                    <td className="text-center">REFERENCE NO</td>
                    <td className="text-center">NARATION</td>
                    <td className="text-center">TYPE</td>
                    <td className="text-center">RECEIVER A/C</td>
                    <td className="text-center">RECEIVER EMAIL</td>
                  </tr>
                  <tr>
                    <td className="text-center">USER</td>
                    <td>DATE</td>
                    <td className="text-center">REFERENCE NO</td>
                    <td className="text-center">NARATION</td>
                    <td className="text-center">TYPE</td>
                    <td className="text-center">RECEIVER A/C</td>
                    <td className="text-center">RECEIVER EMAIL</td>
                  </tr> */}

                  {/* <tr></tr> */}
                  <tr>
                    {show && <td colspan="8" align="center">
                      You have no approved card yet, please contact support to ensure you are issued a card
                    </td>}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-block text-center card-footer">
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
