import Decode from "jwt-decode";
import React, { useLayoutEffect, useState } from "react";
import LoginContext from "./componet/context/login";
import TransferContext from "./componet/context/tranferContext";
import Layout from "./componet/layout/layout";

const Provider = () => {
  const [showTransfer, setShowTransfer] = useState(false);
  const [token, setToken] = useState(null);
  const [details, setDetails] = useState(null);
  const url = "https://api.chartered-expressgroup.com/";
  
  // const url = "http://192.168.1.104:5000/"
  // const url = "http://192.168.43.65:4000/"
  // const url = "http://172.20.10.2:4000/"

  const setShowTransferHandler = () => {
    setShowTransfer(!showTransfer);
  };

  const setTokenHandler = (payload) => {
    
    setToken(payload);
    // console.log("hello wolrd")
    // console.log("hello wolrd")

    setDetails(Decode(payload));
    // localStorage.setItem("token", payload);
  };
  
  

  const refreshTokenHandler  = (payload) => {

    setToken(payload);
    setDetails(Decode(payload));    
    

    
    // console.log("details, details")
    // console.log("details, details")
    // console.log("details, details")
    // console.log("details, details")
    // // console.log("details", details)
    // console.log("details", Decode(payload))
    // // console.log("details", details)
    // console.log("details, details")
    // console.log("details, details")
    // console.log("details, details")
    // console.log("details, details")



  }
 

  const refresh = async () => {
    if(!token) return null
    const fetched = await fetch(`${url}user/get/refresh`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      }),
    });
    const data = await fetched.json();
    if(data.token){
      setToken(data.token);
      setDetails(Decode(data.token));    
    }
    
  };

  useLayoutEffect(()  => {
   setTimeout(() => {
     refresh()
   }, 10000);
  },[token])



  return (
    <LoginContext.Provider
      value={{
        url: url,
        details: details,
        token: token,
        refresh: refresh,
        refreshToken: refreshTokenHandler,
        setToken: setTokenHandler,
      }}
    >
      <TransferContext.Provider
        value={{
          setShowTransfer: setShowTransferHandler,
          showTransfer: showTransfer,
        }}
      >
        <Layout />
      </TransferContext.Provider>
    </LoginContext.Provider>
  );
};

export default Provider;

// vector 76
