import React from "react";
import Classes from "./reuseable.module.css";
import { ReactComponent as CloseSVG } from "../../asset/multiply-1.svg";

export const Card = ({
  children,
  className,
  title,
  svg,
  color = false,
  style,
}) => {
  return (
    <div className={`${Classes.cardCover} ${className}`} style={style}>
      <div className={Classes.CardHeader}>
        <div style={{ color: color ? "#2d8259" : "inherit" }}>{title.toUpperCase()}</div>
        <div className={Classes.CardHeaderIcon}>{svg}</div>
      </div>

      <div className={Classes.form}>{children}</div>
    </div>
  );
};

export const Backdrop = ({ showModal, closeModal,  }) =>
  showModal && (
    <div onClick={closeModal} className={Classes.backdropCover}></div>
  );

export const Modal = ({ showModal, closeModal, children, desc="" }) => {
  return (
    <>
      <Backdrop showModal={showModal} closeModal={closeModal} />
      <div style={{display: showModal ? "block" : "none"}} className={Classes.modalCover}>
        <div className={Classes.CardHeader}>
          <div>{desc}</div>
          <CloseSVG onClick={closeModal} />
        </div>
        {children}
      </div>
    </>
  );
};
