import { nanoid } from "nanoid";
import React, { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import toast, { Toaster } from "react-hot-toast";
// import { FaCcAmazonPay, FaCcVisa } from "react-icons/fa";
import JumboHeader from "../shortcut/jumboHeader";
import CardStatus from "./card-status";

export default function RequestCard() {
  let [nameOnCard, setnameoncard] = useState("");
  let [ccv, setccv] = useState("");
  let [showCard, setShowcard] = useState(false);
  let [cardType, setCardType] = useState("");
  let [focus, setfocus] = useState("");
  let [selectedTab, setSelectedTab] = useState('card-builder')

  return (
    <div className="container-fluid">
      <JumboHeader />
      <Toaster/>
      <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
        <p
          role="tab"
          className="nav-link active btn-danger btn"
          id="tab-0"
          data-toggle="tab"
          style={{backgroundColor: "#2d8259", borderColor: "#2d8259"}}
          onClick={e=>setSelectedTab('card-builder')}
        >
          <span>
            {/* <FaCcVisa /> &#160;  */}
            Request Bank/ATM Card
          </span>
        </p>

        {/* card status */}

       <div style={{margin: "0 10px"}}>
       <p
          role="tab"
          className="nav-link active btn-danger btn"
          id="tab-0"
          data-toggle="tab"
          style={{backgroundColor: "#2d8259", borderColor: "#2d8259"}}
          onClick={e=>setSelectedTab('card-status')}

        >
          <span>
            {/* <FaCcAmazonPay /> &#160; Card Status */}
            {/* <FaCcAmazonPay /> &#160;  */}
            Card Status
          </span>
        </p>
       </div>


      </ul>

      {/* ok next section */}

     {selectedTab === 'card-builder' && <div className="tab-content">
        <div
          className="tab-pane tabs-animation fade show active"
          id="tab-content-0"
          role="tabpanel"
        >
          <div className="main-card mb-3 card">
            <div className="card-body">
              <h5 className="card-title">Confidential Document Application</h5>
              in need of facility? kindly complete the form below, approval take
              1-2 business working days
              <br />
              <br />
              {/* preview */}
              {showCard && (
                <Cards
                  cvc={ccv}
                  expiry={"12/24"}
                  focused={focus}
                  name={nameOnCard}
                  number={
                    cardType === "visa-card"
                      ? "4901"
                      : cardType === "master-card"
                      ? "5299"
                      : "5061" + nanoid(9)
                  }
                />
              )}
              {/* form */}
              <form
                action="/home/view/process.php?action=requestcard"
                method="post"
                onSubmit={e=>{
                    e.preventDefault();
                    let toastID = toast.loading('Your card is been processed', {
                    }); 
                    // clear after 3 sec
                    setTimeout(()=>{
                        toast.dismiss(toastID);
                        toast.error('Your card application was declined, please contact customer support', {
                        duration:5000
                    })
                    },3000)
                }}
              >
                <br />
                <div className="position-relative form-group p-3">
                  <label for="exampleAddress" className="">
                    Bank Card Type
                  </label>
                  <select
                    name="cardtype"
                    required=""
                    className="form-control"
                    style={{ fontSize: "12px", height: "40px", color: "black" }}
                    onChange={(e) => {
                      console.log(e.target.value.trim());
                      if (e.target.value.trim().length > 2) {
                        setShowcard(true);
                        setCardType(e.target.value.trim());
                        return;
                      }
                      setShowcard(false);
                    }}
                  >
                    {" "}
                    /&gt;
                    <option value="">Select Card Type</option>
                    <option value="visa-card"> Visa Card</option>
                    <option value="master-card"> Master Card</option>
                    <option value="maestro-card"> Maestro Card</option>
                  </select>
                </div>

                {showCard && (
                  <>
                    <div className="position-relative form-group p-3">
                      <label for="exampleAddress2" className="">
                        Name on Card
                      </label>
                      <input
                        className="form-control"
                        name="body"
                        placeholder="card name"
                        required={true}
                        value={nameOnCard}
                        onChange={(e) => setnameoncard(e.target.value)}
                        onFocus={(e) => setfocus("name")}
                      />
                    </div>

                    <div className="position-relative form-group p-3">
                      <label for="exampleAddress2" className="">
                        CVC
                      </label>
                      <input
                        className="form-control"
                        name="cvc"
                        placeholder="CVC"
                        required={true}
                        value={ccv}
                        onChange={(e) => {
                          // eslint-disable-next-line use-isnan
                          let cvc =parseInt(e.target.value);
                            setccv(cvc.toString().replace('NaN',''));
                        }}
                        maxLength="3"
                        onFocus={(e) => setfocus("cvc")}
                        onBlur={e=>setfocus('expiry')}
                      />
                    </div>
                  </>
                )}

                <button
                  type="submit"
                  name="login"
                  className="mt-2 btn btn-primary d-flex ai-center"
                >
                  {/* <FaCcVisa /> &#160;  */}
                  Submit Card Application
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    }

    {selectedTab === 'card-status' && <CardStatus/> }
    </div>
  );
}
