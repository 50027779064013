import React, { useContext, useState } from 'react';
import Context from '../../componet/context/login';
import TraferContext from "../../componet/context/tranferContext";
import Transfer from './transfer';

const EventTransfer = () => {
  const ctx = useContext(Context);
  const transCTX = useContext(TraferContext)
  const [inputs, setInputs] = useState({
    amount: '',
    naration: '',
    OTP: '',
    pin: '',
  });
  const [number, setNumber] = useState('');
  const [loadingName, setLoadingName] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const onChangeNumber = (e) => {
    setNumber(e.target.value);
    // if (e.target.value === ctx.details.account_number) {
    //     setMsg('Not Allowed');
    // } else {
        setLoadingName(true);
      fetch(`${ctx.url}user/action/check/account/${e.target.value}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoadingName(false);

          if (res.error) {
            setMsg('Invalid Account Number');
          } else {
            setMsg(`${res.data.fname} ${res.data.lname}`);
          }
        })
        .catch((err) => {
          setLoadingName(false);
          console.log(err);
          setTimeout(() => {
            setMsg('');
          }, 3500);
          setMsg('Check your network connection');
        });
    // }
  };

  const onChangeInputs = (name, e) => {
    const spread = {...inputs};
    spread[name] = e.target.value;
    setInputs(spread);
  };

  const submitFormTransfer = (e) => {
    e.preventDefault();
    if (inputs.pin !== ctx.details.pin) {
       return setMsg('Invalid Pin Check Pin');
    }
    if(loading){
      return
    }
    setLoading(true);
    fetch(`${ctx.url}user/action/transfer/fund`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${ctx.token}`,
      }),
      body: JSON.stringify({
        description: inputs.naration,
        account_number: number,
        amount: inputs.amount,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.error) {
          return setMsg(res.msg);
        } else {
          setMsg("Transaction Successful");
          ctx.setToken(res.data.token)
          transCTX.setShowTransfer()
          setInputs({
            amount: '',
            naration: '',
            OTP: '',
            pin: '',
          })
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setMsg('Check your network connection');
      });
  };

  return (
    <Transfer
      inputs={inputs}
      loadingName={loadingName}
      OnChangeInputHandler={onChangeInputs}
      number={number}
      msg={msg}
      onChangeNumber={onChangeNumber}
      submitFormTransfer={submitFormTransfer}
      loading={loading}
    />
  );
};

export default EventTransfer;
