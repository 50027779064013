import moment from 'moment';
import React, { useContext, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import Context from '../../componet/context/login';
// import { merciImage } from "../../images/merci";
export default function JumboHeader() {
  const ctx = useContext(Context);
  const [mainToken, setMainToken] = useState(`AIB-${ctx.details._id}-tx`);
  let [user, setUser] = useState({});

  // useEffect(() => {
  //   ToastAccountSuccess();
  //   // remove sidebar
  //   document.body.classList.remove('open-side-bar');
  //   let token = localStorage.getItem('token');
  //   if(!token) return window.location.replace('https://api.IBG-UK.com')
  //   let details = decoder(token);
  //   setUser(details);
  // },[]);

  // if the user account is activated then toast
  const ToastAccountSuccess = (e) =>
    toast.success('Account activation successful', {
      position: 'bottom-right',
      style: {
        backgroundColor: '#198754',
        color: '#fff',
      },
    });

  const copyElement = (e) => {
    e.target.focus();
    e.target.select();
    document.execCommand('copy');
    setTimeout(() => {
      setMainToken(`AIB-${ctx.details._id}-tx`);
    }, 1000);
    setMainToken('Copied');
  };

  const styless = {
    backgroundColor: 'inherit',
    border: 'none',
    width: '220px',
    fontWeight: 'bold',
    outline: 'none',
  };

  return (
    <div className="container-fluid">
      <Toaster />
      <div className="card mb-3" style={{maxWidth: '100%'}}>
        <div className="row">
          {/* <div className="col-md-3">
      <img src={merciImage} className="img-fluid rounded-start" alt="..."/>
    </div> */}
          <div style={{width: '100%'}} className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">
                {ctx.details.fname + ' ' + ctx.details.lname}
              </h5>
              <p className="card-text">
                {ctx.details.account_number}
                <span
                  style={{margin: '-7px 0 0 10px'}}
                  className="text-muted badge bg-warning"
                >
                  {' '}
                  {ctx.details.token && (
                    <input
                      readOnly
                      style={styless}
                      value={mainToken}
                      onClick={copyElement.bind(this)}
                    />
                  )}
                </span>{' '}
              </p>
              <p className="card-text">
                <small className="text-muted">{moment().format('LLL')}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
